import { Button, THEME } from "@myloc/myloc-gui";
import { useHistory, withPage } from "@myloc/myloc-utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useHistory as useReactRouterHistory, useParams } from "react-router-dom";
import { useTranslate } from "../../language/i18n";
import { clearOrder, setOrder } from "../../reducers/appData/appDataActions";
import { openOrderPopup, setError, setMessage } from "../../reducers/dialog/dialogAction";
import orderService from "../../services/order/orderService";
import { CLIENT_TYPE, CONTENT_STATUS, CONTENT_TYPE } from "../../utils/constants";
import pages from "../../utils/pages";
import { useMobile } from "../../utils/viewport";
import Checkbox from "../shared/Checkbox/Checkbox";
import MessageBox, { Type } from "../shared/MessageBox/MessageBox";
import {
  Fitting,
  Function,
  NewProduct,
  Pickup,
  ProductDelivery,
  ProductPickup,
  Work,
} from "../shared/OrderListComponents/Types/Types";
import Page from "../shared/Page/Page";
import TotalPrice from "../shared/Price/TotalPrice";
import RecieverInformation from "../shared/RecieverInformation/RecieverInformation";
import AddMessageModal from "./CartModals/AddMessageModal";
import DeleteOrderItemModal from "./CartModals/DeleteOrderItemModal";
import DeleteOrderModal from "./CartModals/DeleteOrderModal";
import SendOrderModal from "./CartModals/SendOrderModal";
import styles from "./CartPage.module.scss";

const CartPage = () => {
  const history = useHistory();
  const isMobile = useMobile();
  const reactRouterHistory = useReactRouterHistory();
  const translate = useTranslate();
  const order = useSelector(state => state.appData.order);
  const queryParams = useParams();
  const params = new URLSearchParams(window.location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [groupedContentList, setGroupedContentList] = useState([]);
  const [contentList, setContentList] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteOrderModalVisible, setDeleteOrderModalVisible] = useState(false);
  const [sendOrderModalVisible, setSendOrderModalVisible] = useState(false);
  const [addMessageModalVisible, setAddMessageModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [filter, setFilter] = useState(params.get("draft") ? true : false);
  const [newOrderId] = useState(reactRouterHistory.location.state?.newOrderId);
  const [checkedJointDelivery, setCheckedJointDelivery] = useState([]);

  const authorizedForMunicipal = order?.receiver?.hasAuthorizationForMunicipal;
  const isBaseStorage = order?.receiver?.type === CLIENT_TYPE.BASE_STORAGE;

  const content = useRef([]);

  const fetchOrders = useCallback(async () => {
    const response = await orderService.getOrder(order.id);
    if (response.isOk()) {
      setContentList(response.data.content);
      content.current = response.data.content;
    }
  }, [order?.id]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      await fetchOrders();
      setIsLoading(false);
    }
    if (order?.id) {
      fetchData();
    }
  }, [fetchOrders, order?.id]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (filter && !searchParams.get("draft")) {
      searchParams.set("draft", true);
    }
    if (!filter) searchParams.delete("draft");

    history.replace(generatePath(pages.CART.PATH, { order: queryParams.order }), searchParams);
    // history not needed as dependency even tho eslint says it is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, queryParams.order]);

  useEffect(() => {
    if (contentList) {
      const groupedOrders = contentList
        .sort((a, b) => a.status - b.status)
        .reduce(
          (accumulator, item) => (
            (accumulator[item.contentType] || (accumulator[item.contentType] = [])).push(item), accumulator
          ),
          {},
        );
      setGroupedContentList(groupedOrders);

      const jointDeliveryArray = [];
      contentList.map(content => {
        if (content.codeliveryId == 1) {
          jointDeliveryArray.push({
            id: content.id,
            addressId: content.address ? content.address.id : content.fromAddress.id,
            deliveryMethodId: content.deliveryMethod?.id,
          });
        }
      });

      setCheckedJointDelivery(jointDeliveryArray);
    }
  }, [contentList]);

  useEffect(() => {
    if (newOrderId) {
      const el = document.getElementById(newOrderId);
      if (el) {
        const header = document.getElementsByTagName("header")[0];
        const headerOffset = header.offsetHeight;
        const scrollToPosition = el.getBoundingClientRect().top - window.pageYOffset - headerOffset;
        window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
      }
    }
  }, [newOrderId, groupedContentList]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (filter || searchParams.get("draft")) {
      setContentList(content.current.filter(order => order.status.value === CONTENT_STATUS.DRAFT));
    } else {
      setContentList(content.current);
    }
    // content.current is needed as dependency even tho eslint says it's not
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, content.current]);

  const handleDeleteOrderItem = async orderData => {
    setModalData(orderData);
    setModalVisible(true);
  };

  const handleDeleteOrder = async ({ order }) => {
    setModalData(order);
    setDeleteOrderModalVisible(true);
  };

  const deleteOrderItem = async orderData => {
    const response = await orderService.deleteOrderItem(orderData.id);
    if (response.isOk()) {
      await fetchOrders();
      setMessage(translate("ORDER_ITEM_DELETED"));
    }
    setModalVisible(false);
  };

  const deleteOrder = async () => {
    const response = await orderService.deleteOrder(order.id);

    if (response.statusCode === 204) {
      setDeleteOrderModalVisible(false);
      setMessage(translate("ORDER_DELETED"));
      reactRouterHistory.push(pages.HOME);
      clearOrder();
    }
  };

  const handleQuantityChange = async (contentId, data) => {
    const response = await orderService.updateLine(contentId, data);
    if (response.isOk()) {
      fetchOrders();
      setMessage(translate("QUANTITY_UPDATED"));
    }
  };

  const handleAssemblyInstallationChange = async (contentId, data) => {
    const response = await orderService.updateContent(order.id, contentId, data);
    if (response.isOk()) {
      fetchOrders();
    }
  };

  const handleSendOrder = async () => {
    setIsLoading(true);
    const response = await orderService.sendOrder(order?.id);
    setIsLoading(false);

    if (response.isOk()) {
      const location = {
        pathname: pages.ORDER_CONFIRMATION.PATH,
        state: response.data,
      };
      reactRouterHistory.push(location);
      clearOrder();
    }
  };

  const onCheckJointDelivery = async item => {
    const response = await orderService.updateContent(item.id, item);

    if (response.isOk()) {
      const jointItem = { id: item.id, addressId: item.address.id, deliveryMethodId: item.deliveryMethod?.id };
      if (checkedJointDelivery.length > 0) {
        const existInArray = checkedJointDelivery.findIndex(checked => checked.id === jointItem.id);
        if (existInArray == -1) {
          setCheckedJointDelivery(oldArray => [...oldArray, jointItem]);
        } else {
          let _array = [...checkedJointDelivery];
          _array.splice(existInArray, 1);
          setCheckedJointDelivery(_array);
        }
      } else setCheckedJointDelivery([jointItem]);

      fetchOrders();
    }
  };

  const onClickSendOrder = () => {
    if (checkedJointDelivery?.length === 1) {
      setError({ error: "exception", errorMessage: translate("MINIMUM_TWO_PRODUCTS_IF_JOINT_DELIVERY") });
      return;
    }
    setSendOrderModalVisible(true);
  };

  const handleAddMessage = async message => {
    const response = await orderService.updateOrder(order.id, { note: message });
    if (response.isOk()) {
      setOrder(response.data);
      setAddMessageModalVisible(false);
    }
  };

  const disableSendButton =
    content.current?.length > 0 && content.current?.some(item => item.status?.value === CONTENT_STATUS.DRAFT);

  const disableJointDelivery = content?.current?.length === 1;

  const emptyCart = content.current?.length === 0;

  return (
    <>
      <Page
        title={`${translate("CART_TITLE")}`}
        description={`${translate("ORDER")} ${order?.orderNumber ? order?.orderNumber : ""}`}
        isLoading={isLoading}
        loadingTitle={"CART_LOADING"}
      >
        <div className={styles.layout}>
          <section className={styles.content}>
            <Checkbox
              onChange={e => setFilter(e.currentTarget.checked)}
              checked={filter}
              label={translate("CART_SHOW_ONLY_DRAFTS")}
              customCssClass={styles.checkBox}
            />
            {groupedContentList[CONTENT_TYPE.FUNCTION] && (
              <Function
                list={groupedContentList[CONTENT_TYPE.FUNCTION]}
                onDelete={handleDeleteOrderItem}
                onQuantityChange={handleQuantityChange}
                enableJointDelivery
                onCheckJointDelivery={onCheckJointDelivery}
                checkedJointDelivery={checkedJointDelivery}
                disableJointDelivery={disableJointDelivery}
              />
            )}
            {groupedContentList[CONTENT_TYPE.PRODUCT_PICKUP] && (
              <ProductPickup
                list={groupedContentList[CONTENT_TYPE.PRODUCT_PICKUP]}
                onDelete={handleDeleteOrderItem}
                onQuantityChange={handleQuantityChange}
              />
            )}
            {groupedContentList[CONTENT_TYPE.PRODUCT_DELIVERY] && (
              <>
                <ProductDelivery
                  list={groupedContentList[CONTENT_TYPE.PRODUCT_DELIVERY]}
                  onDelete={handleDeleteOrderItem}
                  onQuantityChange={handleQuantityChange}
                  onAssemblyInstallationChange={handleAssemblyInstallationChange}
                  enableJointDelivery
                  onCheckJointDelivery={onCheckJointDelivery}
                  checkedJointDelivery={checkedJointDelivery}
                  disableJointDelivery={disableJointDelivery}
                  checkboxDisabled
                  fetchOrders={fetchOrders}
                />
              </>
            )}
            {groupedContentList[CONTENT_TYPE.NEW_PRODUCT] && (
              <NewProduct
                list={groupedContentList[CONTENT_TYPE.NEW_PRODUCT]}
                onDelete={handleDeleteOrderItem}
                onQuantityChange={handleQuantityChange}
              />
            )}
            {groupedContentList[CONTENT_TYPE.FITTING] && (
              <Fitting list={groupedContentList[CONTENT_TYPE.FITTING]} onDelete={handleDeleteOrderItem} />
            )}
            {groupedContentList[CONTENT_TYPE.WORK] && (
              <Work list={groupedContentList[CONTENT_TYPE.WORK]} onDelete={handleDeleteOrderItem} />
            )}
            {groupedContentList[CONTENT_TYPE.PICKUP] && (
              <Pickup
                list={groupedContentList[CONTENT_TYPE.PICKUP]}
                onDelete={handleDeleteOrderItem}
                onQuantityChange={handleQuantityChange}
              />
            )}
            {disableSendButton && (
              <MessageBox
                message={translate("CART_DRAFT_INFO_MESSAGE")}
                type={Type.Warning}
                customCssClass={styles.messageBox}
              />
            )}
            {emptyCart && <p className={styles.emptyCartText}>{translate("CART_EMPTY_TEXT")}</p>}
            {order?.note && (
              <div className={styles.orderMessage}>
                {translate("ORDER_MESSAGE")}:<p>{order.note}</p>
              </div>
            )}
            <TotalPrice list={groupedContentList} />

            <footer>
              <Button
                theme={THEME.SECONDARY}
                customCssClass={styles.button}
                onClick={() => openOrderPopup({ receiverType: order?.receiver?.type })}
                disabled={!isBaseStorage && !authorizedForMunicipal}
              >
                {translate("CONTINUE_ORDER")}
              </Button>

              {!isBaseStorage && (
                <Button
                  theme={THEME.SECONDARY}
                  customCssClass={styles.button}
                  onClick={() => reactRouterHistory.push(pages.BASE_STORAGES.PATH)}
                >
                  {translate("ORDER_FROM_BASE_STORAGE")}
                </Button>
              )}

              <Button theme={THEME.SECONDARY} onClick={() => setAddMessageModalVisible(true)}>
                {translate("ADD_CART_MESSAGE")}
              </Button>

              <Button theme={THEME.SECONDARY} onClick={handleDeleteOrder}>
                {translate("DELETE_ORDER_CONTENT")}
              </Button>

              <Button
                disabled={
                  disableSendButton ||
                  emptyCart ||
                  (order?.receiver?.type === CLIENT_TYPE.CLIENT && !authorizedForMunicipal)
                }
                onClick={onClickSendOrder}
              >
                {translate("SEND_ORDER")}
              </Button>
            </footer>
          </section>
          {!isMobile && <RecieverInformation customCssClass={styles.info} />}
        </div>
      </Page>
      {modalVisible && (
        <DeleteOrderItemModal
          visible={modalVisible}
          orderData={modalData}
          onClose={() => {
            setModalVisible(false);
            setModalData({});
          }}
          onDelete={deleteOrderItem}
        />
      )}
      {addMessageModalVisible && (
        <AddMessageModal
          visible={addMessageModalVisible}
          onClose={() => setAddMessageModalVisible(false)}
          handleAddMessage={handleAddMessage}
          currentMessage={order?.note}
        />
      )}
      {deleteOrderModalVisible && (
        <DeleteOrderModal
          visible={deleteOrderModalVisible}
          order={order}
          onClose={() => {
            setDeleteOrderModalVisible(false);
          }}
          onDelete={deleteOrder}
        />
      )}
      {sendOrderModalVisible && (
        <SendOrderModal
          isLoading={isLoading}
          visible={sendOrderModalVisible}
          onSend={handleSendOrder}
          onClose={() => setSendOrderModalVisible(false)}
        />
      )}
    </>
  );
};

export default withPage(CartPage);
