import { Button, THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import PropType from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { useTranslate } from "../../../../language/i18n";
import { setOrder } from "../../../../reducers/appData/appDataActions";
import { setReferencedOrder } from "../../../../reducers/embedded/embeddedActions";
import orderService from "../../../../services/order/orderService";
import { CONTENT_PATH, CONTENT_STATUS, CONTENT_TYPE } from "../../../../utils/constants";
import OrderOverviewModal from "../../../Cart/CartModals/OrderOverviewModal";
import FileUpload from "../../../shared/File/FileUpload/FileUpload";
import Label from "../../Label/Label";
import styles from "./ListComponents.module.scss";

//Components used e.g in Cart and Order detail

export const ContentType = ({ title, children }) => (
  <section className={styles.contentType}>
    <h3 className={styles.title}>{title}</h3>
    {children}
  </section>
);

export const Item = ({
  children,
  orderData,
  onDelete,
  addressTitle,
  readOnly,
  isEmbedded,
  isReferencedOrder,
  missingDeliveryAddress,
}) => {
  const translate = useTranslate();

  const hideButtons = isReferencedOrder && !isEmbedded;
  const statusClass = readOnly ? null : orderData.status.value !== CONTENT_STATUS.DRAFT ? styles.ready : styles.draft;
  const statusText =
    orderData.status.value !== CONTENT_STATUS.CART && orderData.status.value !== CONTENT_STATUS.DRAFT ? (
      <p
        className={`${styles.statusLabel} ${
          orderData.externalStatus ? styles.status80 : styles[`status${orderData.status.value}`]
        }`}
      >
        {orderData.externalStatus?.value ? orderData.externalStatus?.label : orderData.status?.label}
      </p>
    ) : orderData.status.value === CONTENT_STATUS.CART || orderData.status.value !== CONTENT_STATUS.DRAFT ? (
      <p className={styles.markDone}>{orderData.status?.label}</p>
    ) : missingDeliveryAddress ? (
      <p className={styles.markDraft}>{translate("MISSING_DELIVERY_ADRESS")}</p>
    ) : (
      <p className={styles.markDraft}>{translate("DRAFT")}</p>
    );
  return (
    <article
      className={classNames(
        styles.contentItem,
        statusClass,
        orderData.externalStatusComment ? styles.statusCommentShown : undefined,
      )}
      id={orderData.id}
    >
      {children}
      {!missingDeliveryAddress && (
        <Address
          address={orderData.address || orderData.fromAddress}
          title={addressTitle}
          deliveryMethod={orderData.deliveryMethod}
        />
      )}
      {orderData.externalStatusComment && <p className={styles.statusComment}>{orderData.externalStatusComment}</p>}

      {orderData.contentType !== CONTENT_TYPE.PRODUCT_PICKUP && !hideButtons && (
        <FileUpload
          contentId={orderData.id}
          files={orderData?.files}
          customCssClass={classNames(styles.fileUpload, !readOnly && styles.margin)}
          iconSize={30}
          noFitting
        />
      )}
      {readOnly || hideButtons || (isReferencedOrder && orderData.status.value !== CONTENT_STATUS.DRAFT) ? (
        <ActionButtonsReadOnly orderData={orderData} referencedOrder={isReferencedOrder} />
      ) : (
        <ActionButtons
          orderData={orderData}
          onDelete={onDelete}
          isEmbedded={isEmbedded}
          isReferencedOrder={isReferencedOrder}
          referencedOrder={isReferencedOrder}
        />
      )}
      {orderData?.contentNumber && <span className={styles.contentNumber}>{orderData.contentNumber}</span>}
      {statusText}
    </article>
  );
};

export const ItemInfo = ({ children, grid = true }) => (
  <div className={classNames(styles.itemInfo, grid && styles.grid)}>{children}</div>
);

export const Row = ({ title, text, margin }) => (
  <p className={margin && styles.marginBottom}>
    {title && <span className={styles.rowTitle}>{title}:</span>} <span className={styles.text}>{text}</span>
  </p>
);

export const RowTitle = ({ text }) => <p className={styles.contentTitle}>{text}</p>;

export const Address = ({ title, address, deliveryMethod }) => {
  const translate = useTranslate();

  if (!address) {
    return null;
  }

  return (
    <article className={styles.addressContainer}>
      <p className={styles.rowTitle}>{title || translate("DELIVERY_ADDRESS")}:</p>
      <p className={styles.text}>
        {address?.description && (
          <span>
            {address?.facility?.category?.label === translate("BASE_STORAGE") && address?.facility?.value + " - "}{" "}
            {address?.facility?.category?.label === translate("BASE_STORAGE")
              ? address?.facility?.label
              : address?.description}
          </span>
        )}
        {address?.careOf && <span>{address.careOf}</span>}
        {address?.street && <span>{address.street}</span>}
        {(address?.postalNo || address?.city) && (
          <span>
            {address?.postalNo} {address?.city}
          </span>
        )}
      </p>
      {deliveryMethod && <Label label={translate("DELIVERY_METHOD")} value={deliveryMethod.name} inline />}
    </article>
  );
};

export const ActionButtons = ({ orderData, onDelete, isEmbedded, referencedOrder, isReferencedOrder }) => {
  const translate = useTranslate();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const order = useSelector(state => state.appData.order);

  const showEditButton =
    (orderData.contentType === CONTENT_TYPE.PRODUCT_DELIVERY && orderData?.steps?.length > 0) ||
    ![CONTENT_TYPE.PRODUCT_DELIVERY, CONTENT_TYPE.PRODUCT_PICKUP].includes(orderData.contentType);

  const editDraft = async () => {
    let fetchedOrder;
    if (referencedOrder) {
      fetchedOrder = await orderService.setOrder(orderData.orderId);
      if (fetchedOrder.isOk) {
        setReferencedOrder({ fitting: isReferencedOrder });
        setOrder(fetchedOrder?.data);
      }
    }

    history.push(
      generatePath(
        isEmbedded ? `/embedded${CONTENT_PATH[orderData.contentType]}` : CONTENT_PATH[orderData.contentType],
        {
          order: order?.id || fetchedOrder?.data?.id,
          content: orderData.id,
        },
      ),
    );
  };

  return (
    <section className={styles.actionButtons}>
      {orderData.status.value === CONTENT_STATUS.DRAFT && (order?.id || orderData.orderId) ? (
        <Button theme={THEME.PLAIN_PRIMARY} customCssClass={styles.button} onClick={() => editDraft()}>
          {translate("EDIT_ORDER_CONTENT")}
        </Button>
      ) : (
        showEditButton &&
        orderData.status.value === CONTENT_STATUS.CART &&
        !referencedOrder && (
          <Button theme={THEME.PLAIN_PRIMARY} customCssClass={styles.button} onClick={() => setVisible(true)}>
            {translate("EDIT_ORDER_CONTENT")}
          </Button>
        )
      )}
      <Button theme={THEME.PLAIN_PRIMARY} onClick={() => onDelete(orderData)}>
        {translate("DELETE_ORDER_ITEM_CONTENT")}
      </Button>

      {visible && (
        <OrderOverviewModal
          visible={visible}
          orderId={order?.id || orderData?.orderId}
          orderData={orderData}
          onClose={() => setVisible(false)}
        />
      )}
    </section>
  );
};

export const ActionButtonsReadOnly = ({ orderData, referencedOrder }) => {
  const translate = useTranslate();
  const [visible, setVisible] = useState(false);

  //TODO Will it always be shown?
  const showEditButton = ![CONTENT_TYPE.PRODUCT_DELIVERY, CONTENT_TYPE.PRODUCT_PICKUP].includes(orderData.contentType);

  //TBD where show/edit link will take the user
  return (
    <section className={styles.actionButtons}>
      {(showEditButton || referencedOrder) && (
        <Button theme={THEME.PLAIN_PRIMARY} onClick={() => setVisible(true)}>
          {translate("VIEW_ORDER_CONTENT")}
        </Button>
      )}
      {visible && (
        <OrderOverviewModal
          readOnly
          visible={visible}
          orderId={orderData.id}
          orderData={orderData}
          onClose={() => setVisible(false)}
        />
      )}
    </section>
  );
};

ContentType.propTypes = {
  title: PropType.string,
  children: PropType.node,
};

Item.propTypes = {
  children: PropType.node,
  orderData: PropType.shape({
    id: PropType.string,
    orderId: PropType.string,
    steps: PropType.array,
    currentStep: PropType.number,
    address: PropType.object,
    fromAddress: PropType.object,
    status: PropType.object,
    externalStatus: PropType.object,
    externalStatusComment: PropType.string,
    files: PropType.array,
    contentType: PropType.string,
    contentNumber: PropType.string,
    deliveryMethod: PropType.object,
  }),
  addressTitle: PropType.string,
  onDelete: PropType.func,
  readOnly: PropType.bool,
  isEmbedded: PropType.bool,
  isReferencedOrder: PropType.object,
  missingDeliveryAddress: PropType.bool,
};
ItemInfo.propTypes = {
  children: PropType.node,
  grid: PropType.bool,
};

Row.propTypes = {
  title: PropType.string,
  text: PropType.string,
  margin: PropType.bool,
};

RowTitle.propTypes = {
  text: PropType.string,
};

Address.propTypes = {
  title: PropType.string,
  address: PropType.object,
  deliveryMethod: PropType.object,
};

ActionButtons.propTypes = {
  orderData: PropType.object,
  onDelete: PropType.func,
  isEmbedded: PropType.bool,
  referencedOrder: PropType.bool,
  isReferencedOrder: PropType.object,
};

ActionButtonsReadOnly.propTypes = {
  orderData: PropType.object,
  referencedOrder: PropType.bool,
};
