import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "../CompanyLogoList.module.scss";
import HÖSMunicipalityLogo from "../../../shared/MunicipalityLogo/HÖSMunicipalityLogo";

const CompanyLogoList = ({ customCssClass }) => (
  <section className={classNames(styles.list, customCssClass)}>
    <HÖSMunicipalityLogo id="Kristianstad" customCssClass={styles.logo} />
    <HÖSMunicipalityLogo id="Bromölla" customCssClass={styles.logo} />
    <HÖSMunicipalityLogo id="Hässleholm" customCssClass={styles.logo} />
    <HÖSMunicipalityLogo id="Hörby" customCssClass={styles.logo} />
    <HÖSMunicipalityLogo id="Osby" customCssClass={styles.logo} />
    <HÖSMunicipalityLogo id="Simrishamn" customCssClass={styles.specialLogo} />
    <HÖSMunicipalityLogo id="ÖstraGöinge" customCssClass={styles.logo} />
    <HÖSMunicipalityLogo id="Tomelilla" customCssClass={styles.logoNoWidth} />
    <HÖSMunicipalityLogo id="Ystad" customCssClass={styles.logo} />
    <HÖSMunicipalityLogo id="Sjöbo" customCssClass={styles.specialLogo} />
    <HÖSMunicipalityLogo id="Skurup" customCssClass={styles.specialLogo} />
  </section>
);

CompanyLogoList.propTypes = {
  customCssClass: PropTypes.string,
};

export default CompanyLogoList;
