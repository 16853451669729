import { memo } from "react";
import PropTypes from "prop-types";
import Bromolla_full from "../../../assets/logos/HÖS/Bromolla_full.png";
import Hassleholm_full from "../../../assets/logos/HÖS/Hassleholm_full.png";
import Horby_full from "../../../assets/logos/HÖS/Horby_full.png";
import Osby_full from "../../../assets/logos/HÖS/Osby_full.png";
import Simrishamn_full from "../../../assets/logos/HÖS/Simrishamn_full.png";
import OstraGoinge_full from "../../../assets/logos/HÖS/OstraGoinge_full.png";
import Sjobo_full from "../../../assets/logos/HÖS/Sjobo_full.png";
import Skurup_full from "../../../assets/logos/HÖS/Skurup_full.png";
import Ystad_full from "../../../assets/logos/HÖS/Ystad_full.png";
import Kristianstad_full from "../../../assets/logos/HÖS/Kristianstad_full.png";
import Tomelilla_full from "../../../assets/logos/HÖS/Tomelilla_full.png";

const logos = [
  {
    id: "Bromölla",
    alt: "Bromölla kommun",
    fullLogo: Bromolla_full,
    logo: "",
  },
  {
    id: "Hässleholm",
    alt: "Hässleholm kommun",
    fullLogo: Hassleholm_full,
    logo: "",
  },
  {
    id: "Hörby",
    alt: "Hörby kommun",
    fullLogo: Horby_full,
    logo: "",
  },
  {
    id: "Osby",
    alt: "Osby kommun",
    fullLogo: Osby_full,
    logo: "",
  },
  {
    id: "Simrishamn",
    alt: "Simrishamn kommun",
    fullLogo: Simrishamn_full,
    logo: "",
  },
  {
    id: "ÖstraGöinge",
    alt: "Östra Göinge kommun",
    fullLogo: OstraGoinge_full,
    logo: "",
  },
  {
    id: "Sjöbo",
    alt: "Sjöbo kommun",
    fullLogo: Sjobo_full,
    logo: "",
  },
  {
    id: "Skurup",
    alt: "Skurup kommun",
    fullLogo: Skurup_full,
    logo: "",
  },
  {
    id: "Ystad",
    alt: "Ystad kommun",
    fullLogo: Ystad_full,
    logo: "",
  },
  {
    id: "Kristianstad",
    alt: "Kristianstad kommun",
    fullLogo: Kristianstad_full,
    logo: "",
  },
  {
    id: "Tomelilla",
    alt: "Tomelilla kommun",
    fullLogo: Tomelilla_full,
    logo: "",
  },
];

function HÖSMunicipalityLogo({ id, customCssClass, fullLogo = true, imgLink }) {
  if (imgLink) {
    return <embed src={imgLink} alt={id} className={customCssClass} />;
  }

  if (!id) {
    return;
  }

  const img = logos.find(logo => logo.id === id);

  let logo;
  if (imgLink) {
    logo = imgLink;
  } else if (fullLogo) {
    logo = img.fullLogo;
  } else {
    logo = img.logo;
  }

  return img ? <embed src={logo} alt={img.alt} className={customCssClass} /> : null;
}

HÖSMunicipalityLogo.propTypes = {
  id: PropTypes.string.isRequired,
  fullLogo: PropTypes.bool,
  customCssClass: PropTypes.string,
  imgLink: PropTypes.string,
};

export default memo(HÖSMunicipalityLogo);
